import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {PRIVACY} from "../components/INFO/privacyInfo";

const PrivacyPolicyPage = () => {

    return (
        <Layout showHero={false}>
            <SEO title="プライバシーポリシー"/>
            <section className="my-10 md:my-6 mx-auto px-auto: py-2 md:py-3 flex flex-col items-center">
                <div className="flex flex-col justify-between text-center">
                    {PRIVACY.map((index) => (
                    <div key={index.title}>
                        <h3 className="text-lg my-1">{index.title}</h3>
                        <p className="my-2 text-sm text-gray-400">{index.description}</p>
                    </div>))}
                </div>
                <a href="/#contact" className="text-center text-gray-500 text-sm">お問い合わせはこちらです</a>
                <div className="w-1/4 text-center border-double border-4 border-gray-500 mx-6 my-2 hover:bg-blue-500 transition duration-300 ease-in-out">
                    <Link to="/" className="" >Topへ</Link>
                 </div>
            </section>
        </Layout>
    );
};

export default PrivacyPolicyPage