export  const  PRIVACY =[
    {title: "個人情報の利用目的", description : `本ウェブサイトでは、お客様からのお問い合わせ時に、お名前、e-mailアドレス、電話番号等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。
    お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。`,},
    {title: "個人情報の第三者への開示・提供の禁止", description: `個人情報の第三者への開示・提供の禁止
    当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
    ・お客さまの同意がある場合
    ・お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
    ・法令に基づき開示することが必要である場合`},
    {title: "個人情報の安全対策", description: `当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。`},
    {title: "ご本人の照会", description: `お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。`},
    {title: "法令、規範の遵守と見直し", description: `当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。`},
    {title: "アクセス解析ツールについて", description: `当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
    `},
    {title: "お問い合わせ", description: "お問い合わせフォームにご連絡ください。"}

]